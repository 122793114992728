// case study slider
$(".cs-slider").slick({
    infinite: true,
    arrows: false,
    dots: true,
    autoplay: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    appendDots:'.cs-dots'
});

$('.cs-item:first').addClass('active');
$('.cs-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $('.cs-item.active').removeClass('active');
    $('.cs-item').eq(nextSlide).addClass('active');
});

$('.cs-item').click(function () {
    var goToThisIndex = $(this).data("slickIndex");
    $('.cs-slider').slick('slickGoTo', goToThisIndex, false);
});

if ($(window).width() > 640) {
    var csslidertitle = $('.cs-slider-title').height();
    $('.cs-slider-title').css('margin-bottom','-'+csslidertitle+'px');
    $('.cs-slider-container').css('margin-top',(csslidertitle + 40)+'px');
 }
 else {
    $('.cs-slider-title').css('margin-bottom','15px');
    $('.cs-slider-container').css('margin-top','inherit');
 }

 window.addEventListener("resize", function() {
    if ($(window).width() > 640) {
        var csslidertitle = $('.cs-slider-title').height();
        $('.cs-slider-title').css('margin-bottom','-'+csslidertitle+'px');
        $('.cs-slider-container').css('margin-top',(csslidertitle + 40)+'px');
    } else {
        $('.cs-slider-title').css('margin-bottom','15px');
        $('.cs-slider-container').css('margin-top','inherit');
    }
});


