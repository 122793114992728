import '../scss/foundation.scss';
import '../scss/main.scss';

import lax from 'lax.js';
import slick from 'slick-carousel';

// Either import everything
//import 'foundation-sites;

// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';//
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.reveal';

import './modules/articleSlider';
import './modules/caseStudySlider';
import './modules/videoSlider';
import './modules/teamSlider';

// ie checker
function detectIEEdge() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
		// IE 10 or older => return version number
		//alert('ie 10');
		document.body.classList.add("ie-fix");
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
		// IE 11 => return version number
		document.body.classList.add("ie-fix");
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

	var safari = ua.indexOf('Safari');
    if (safari > 0) {
		if(ua.indexOf('Chrome') > -1){

		} else {
			console.log('safari');
			document.body.classList.add("safari-fix");
		}
    }

    // other browser
    return false;
}

//console.log('Entry Loaded');
document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	detectIEEdge();

	// mobile hamburger menu
	$('.hamburger').on('click', function (e) {
		$('.hamburger').toggleClass('is-active');
		$('.nav-menu-container').toggleClass('is-active');
		$('.sec-menu').removeClass('menu-active');
		e.preventDefault();
	});

	// subnav
	$('.btn-job-search').on('click', function (e) {
		$('.subnav-link').toggleClass('is-open');
		$('.subnav').toggleClass('is-open');
		e.preventDefault();
	});

	//secondary menu
	$('.secondary-menu-btn').on('click', function (e) {
		e.preventDefault();
		$('.sec-menu').toggleClass('menu-active');
		$(this).toggleClass('is-active');
		//$('.sec-menu-link').first().addClass('sec-menu-active');
	});

	// $('.sec-menu-find').on("mouseover", function () {
	// 	$('.sec-menu-link').removeClass('sec-menu-active');
	// 	$(this).addClass('sec-menu-active');
	// 	$('.sec-menu-sub-find').addClass('is-active');
	// 	$('.sec-menu-sub-excite').removeClass('is-active');
	// 	$('.sec-menu-sub-secure').removeClass('is-active');
	// });
	// $('.sec-menu-excite').on("mouseover", function () {
	// 	$('.sec-menu-link').removeClass('sec-menu-active');
	// 	$(this).addClass('sec-menu-active');
	// 	$('.sec-menu-sub-find').removeClass('is-active');
	// 	$('.sec-menu-sub-excite').addClass('is-active');
	// 	$('.sec-menu-sub-secure').removeClass('is-active');
	// });
	// $('.sec-menu-secure').on("mouseover", function () {
	// 	$('.sec-menu-link').removeClass('sec-menu-active');
	// 	$(this).addClass('sec-menu-active');
	// 	$('.sec-menu-sub-find').removeClass('is-active');
	// 	$('.sec-menu-sub-excite').removeClass('is-active');
	// 	$('.sec-menu-sub-secure').addClass('is-active');
	// });

	$('#maincontent').on('click', function (e) {
		//e.preventDefault();
		$('.header').removeClass('search-is-open');
		$('.sec-menu').removeClass('menu-active');
		$('.secondary-menu-btn').removeClass('is-active');
		$('.search-container').removeClass('is-open');
		$('.subnav-link').removeClass('is-open');
		$('.subnav').removeClass('is-open');
		$('.nav-menu-container').removeClass('is-active');
		$('.hamburger').removeClass('is-active');		
	});

	$('.btn-site-search').on('click', function (e) {
		$('.header').toggleClass('search-is-open');
		$('.search-container').toggleClass('is-open');
		e.preventDefault();
	});

	// back to top button
	var btn = $('#topbutton');

	$(window).scroll(function () {
		if ($(window).scrollTop() > 300) {
			btn.addClass('show');
		} else {
			btn.removeClass('show');
		}
	});

	btn.on('click', function (e) {
		e.preventDefault();
		$('html, body').animate({ scrollTop: 0 }, '300');
	});

	// video list modal open
	$('.video-list-modal-link').on('click', function() {
		$('#video-list-modal').foundation('open');		     
		$('.embed-player').attr("src", $(this).attr('data-video-link'));
		$('.embed-player').attr("title", $(this).attr('data-video-title'));
	});

	// reveal video close and stop
	$('.reveal-overlay').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .close-button').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$(document).on('keyup',function(evt) {
		if (evt.keyCode == 27) {
			$('.reveal-video iframe').attr('src', '');
		}
	});

	// home tabs
	// $('.home-intro-tab').click(function () {
	// 	var t = $(this).data('tab');
	// 	if ($('.home-intro-bg').hasClass(t)) {
	// 		$('.home-intro-bg').removeClass('active');
	// 		$('.home-intro-bg.' + t).addClass('active');
	// 	}

	// 	$('.home-intro-tab').removeClass('active');
	// 	$(this).addClass('active');

	// 	if ($('.home-intro-tab-content').hasClass(t)) {
	// 		$('.home-intro-tab-content').removeClass('active');
	// 		$('.home-intro-tab-content.' + t).addClass('active');
	// 	}
	// });

	// service carousel
	$('.service-slider').slick({
		infinite: true,
		dots: true,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 3000,
		fade: true,
		fadeSpeed: 1000
	});
	// service dots
	$('.service-dots .slick-dot').click(function () {
		var goToThisIndex = $(this).data("slickIndex");
		console.log('service dot', goToThisIndex);
		$('.service-slider').slick('slickGoTo', goToThisIndex, false);
	});

	// other work carousel
	$('.other-work-slider').slick({
		dots: true,
		arrows: false,
		infinite: false,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 1,
		appendDots: '.other-work-dots'
	});

	// scrolling animations
	lax.setup() // init
	const updateLax = () => {
		lax.update(window.scrollY)
		window.requestAnimationFrame(updateLax)
	}
	window.requestAnimationFrame(updateLax)

	window.addEventListener("resize", function () {
		//console.log('lax update');
		lax.updateElements();
	});

	// scrolling add class
	$.fn.visible = function (partial) {

		var $t = $(this),
			$w = $(window),
			viewTop = $w.scrollTop(),
			viewBottom = viewTop + $w.height(),
			_top = $t.offset().top,
			_bottom = _top + $t.height(),
			compareTop = partial === true ? _bottom : _top,
			compareBottom = partial === true ? _top : _bottom;

		return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

	};

	// image seq scroll	
	if ($("[data-animated-block]").hasClass('img-seq-trigger')) {
		var scrollCount = 0,
			latestScrollTop = 0,
			doc = document.documentElement,
			top = 0,
			imgLimit = $('.img-seq').attr('data-sequence-limit'),
			dataSrc = $('.img-seq').attr('data-image-src');
	}

	$(window).scroll(function (event) {

		$("[data-animated-block]").each(function (i, el) {
			var el = $(el);
			if (el.visible(true)) {
				el.addClass("animateIn");
			} else {
				if (!el.hasClass('stat-block')) {
					el.removeClass("animateIn");
				}
			}

			// countup init		  
			if (el.hasClass('stat-block')) {
				$('.stat').each(function () {
					var $this = $(this),
						countTo = $this.attr('data-count');

					$({ countNum: $this.text() }).animate({
						countNum: countTo
					},
						{
							duration: 1000,
							easing: 'linear',
							step: function () {
								$this.text(Math.floor(this.countNum));
							},
							complete: function () {
								$this.text(this.countNum);
							}
						});
				});
			}
			if (el.hasClass('img-seq-trigger')) {
				if (el.visible(true)) {
					top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

					if (latestScrollTop < top) {
						if (scrollCount < 1) {
							scrollCount = 1;
						}
						if (scrollCount > imgLimit) {
							scrollCount = imgLimit;
						}
						if (scrollCount > 1 || scrollCount < imgLimit) {
							scrollCount += 1;
						}
					} else {
						if (scrollCount < 1) {
							scrollCount = 1;
						}
						if (scrollCount > imgLimit) {
							scrollCount = imgLimit;
						}
						if (scrollCount > 1 || scrollCount < imgLimit) {
							scrollCount -= 1;
						}
					}
					latestScrollTop = top;
					if (parseInt(scrollCount) === scrollCount) {
						$('.img-seq').attr("src", "" + dataSrc + scrollCount + ".png");
					}
					if (scrollCount < 1) {
						$('.img-seq').attr("src", "" + dataSrc + "/1.png");
					}
					if (scrollCount > imgLimit) {
						$('.img-seq').attr("src", "" + dataSrc + imgLimit + ".png");
					}
				}
			}
		});
	});

	//console.log('loaded');
});
