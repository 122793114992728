	// article slider START
	$(".article-slider").slick({
		infinite: true,
		arrows: false,
		dots: true,
		autoplay: false,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true
	});

	$('.progressBarContainer .article-item:first').addClass('active');
	if ($('.slide-item').hasClass('article-has-stat')) {
		$('.stat').each(function () {
			var $this = $(this),
				countTo = $this.attr('data-count');

			$({ countNum: $this.text() }).animate({
				countNum: countTo
			},
				{
					duration: 1000,
					easing: 'linear',
					step: function () {
						$this.text(Math.floor(this.countNum));
					},
					complete: function () {
						$this.text(this.countNum);
					}
				});
		});
	}
	$('.article-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		$('.stat').text(0);		
		$('.progressBarContainer .article-item.active').removeClass('active');
		$('.progressBarContainer .article-item').eq(nextSlide).addClass('active');
		if ($('.slide-item').hasClass('article-has-stat')) {
			$('.stat').each(function () {
				var $this = $(this),
					countTo = $this.attr('data-count');
	
				$({ countNum: $this.text() }).animate({
					countNum: countTo
				},
					{
						duration: 1000,
						easing: 'linear',
						step: function () {
							$this.text(Math.floor(this.countNum));
						},
						complete: function () {
							$this.text(this.countNum);
						}
					});
			});
		}
	});
	  
	//ticking machine
	var percentTime;
	var tick;
	var time = .1;
	var progressBarIndex = 0;
	var isPaused = false;

	$('.progressBarContainer .article-item').on({
		mouseenter: function () {
			isPaused = true;
		},
		mouseleave: function () {
			isPaused = false;
		}
	});
	  
	$('.progressBarContainer .progressBar').each(function(index) {
		var progress = "<div class='inProgress inProgress" + index + "'></div>";
		$(this).html(progress);
	});
	  
	function startProgressbar() {
		resetProgressbar();
		percentTime = 0;
		tick = setInterval(interval, 10);
	}
	  
	function interval() {
		if (($('.article-slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
			progressBarIndex = $('.article-slider .slick-track div[aria-hidden="false"]').data("slickIndex");			
			startProgressbar();
		} else {
			if(isPaused === false){
				percentTime += 1 / (time + 5);
				$('.inProgress' + progressBarIndex).css({
					width: percentTime + "%"
				});
				if (percentTime >= 100) {
					$('.article-slider').slick('slickNext');
					progressBarIndex++;
					if (progressBarIndex > 2) {
						progressBarIndex = 0;
					}
					startProgressbar();
				}
			}
		}
	}

	function resetProgressbar() {
		$('.inProgress').css({
			width: 0 + '%'
		});
		clearInterval(tick);
	}
	startProgressbar();
	// End ticking machine
	  
	$('.article-item').click(function () {
		clearInterval(tick);
		var goToThisIndex = $(this).find("span").data("slickIndex");
		$('.article-slider').slick('slickGoTo', goToThisIndex, false);
		startProgressbar();
	});
	// article slider END