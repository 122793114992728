// video carousel
$('.video-slider').slick({
  infinite: true,
  dots: true,
  arrows: false,
  autoplay: false,
  fade: true,
  fadeSpeed: 1000
});
$('.video-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  $('.slick-current iframe').attr('src', $('.slick-current iframe').attr('src'));
});