// case study slider
$(".team-slider").slick({
    infinite: true,
    arrows: true,
    dots: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    appendArrows:'.team-arrows'
});

$('.team-item:first').addClass('active');
$('.team-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $('.team-item.active').removeClass('active');
    $('.team-item').eq(nextSlide).addClass('active');
});

$('.team-item').click(function () {
    var goToThisIndex = $(this).data("slickIndex");
    $('.team-slider').slick('slickGoTo', goToThisIndex, false);
});

// if ($(window).width() > 640) {
//     console.log('desktop');
//     var csslidertitle = $('.cs-slider-title').height();
//     $('.cs-slider-title').css('margin-bottom','-'+csslidertitle+'px');
//     $('.cs-slider-container').css('margin-top',(csslidertitle + 40)+'px');
//  }
//  else {
//     $('.cs-slider-title').css('margin-bottom','inherit');
//     $('.cs-slider-container').css('margin-top','inherit');
//  }

//  window.addEventListener("resize", function() {
//     if ($(window).width() > 640) {
//         var csslidertitle = $('.cs-slider-title').height();
//         $('.cs-slider-title').css('margin-bottom','-'+csslidertitle+'px');
//         $('.cs-slider-container').css('margin-top',(csslidertitle + 40)+'px');
//     } else {
//         $('.cs-slider-title').css('margin-bottom','inherit');
//         $('.cs-slider-container').css('margin-top','inherit');
//     }
// });


